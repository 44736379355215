import { Component, OnInit, OnDestroy } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { DateTimeModel } from '@shared/date-time-picker/date-time.model';
import { toNgbDateStruct } from '@shared/date-utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-query-box',
  templateUrl: './query-box.component.html',
  styleUrls: ['./query-box.component.scss'],
})
export class QueryBoxComponent implements OnInit, OnDestroy {
  faCalendar = faCalendar;

  inputForm!: UntypedFormGroup;

  startDateMin = toNgbDateStruct(DateTimeModel.fromLocalString('01/01/1900', DateTimeModel.DATE_FORMAT));
  startDateMax: NgbDateStruct;
  endDateMin: NgbDateStruct;

  private valueChanges: Subscription;

  constructor(
    private calendar: NgbCalendar,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    const endDate = dayjs();
    const startDate = endDate.subtract(1, 'day');

    this.startDateMax = toNgbDateStruct(startDate);
    this.endDateMin = toNgbDateStruct(endDate);

    this.inputForm = this.formBuilder.group({
      startDate: [startDate.format(DateTimeModel.DATE_FORMAT), Validators.required],
      endDate: [endDate.format(DateTimeModel.DATE_FORMAT), Validators.required],
    });

    this.valueChanges = this.inputForm.valueChanges.subscribe((changes) => {
      const startDate = dayjs(changes.startDate, DateTimeModel.DATE_FORMAT);
      const endDate = dayjs(changes.endDate, DateTimeModel.DATE_FORMAT);

      if (startDate) {
        this.endDateMin = toNgbDateStruct(startDate.add(1, 'day'));
      }

      if (endDate) {
        this.startDateMax = toNgbDateStruct(endDate.subtract(1, 'day'));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.valueChanges) {
      this.valueChanges.unsubscribe();
    }
  }

  get startDate() {
    return this.inputForm.get('startDate')?.value;
  }

  get endDate() {
    return this.inputForm.get('endDate')?.value;
  }
}
