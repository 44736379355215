import { Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private workingNotifications = new Array<number>();

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private translate: TranslateService,
  ) {}

  get toastr() {
    return this.injector.get(ToastrService);
  }

  notify(msg: string) {
    this.hideWorking();

    this.toastr.success(msg);
  }

  notifyWorking() {
    this.workingNotifications.push(
      this.info(this.translate.instant('notifications.shell.common.working-text'), this.showAlways()).toastId,
    );
  }

  info(msg: string, override: Partial<IndividualConfig>) {
    return this.toastr.info(msg, '', override);
  }

  error(msg: string) {
    this.hideWorking();

    this.toastr.error(msg);
  }

  hideWorking() {
    this.workingNotifications.forEach((n) => {
      this.toastr.remove(n);
    });

    this.workingNotifications = [];
  }

  showAlways() {
    return {
      disableTimeOut: true,
      tapToDismiss: false,
    };
  }
}
