import { Routes, Route } from '@angular/router';
import { ShellComponent } from './shell.component';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Logger } from '@shared';
import { AuthService } from '@app/auth/auth.service';
import { NotificationService } from '@app/services/notification.service';

const log = new Logger('Shell');

/**
 * Provides helper methods to create routes.
 */
@Injectable({
  providedIn: 'root',
})
export class Shell {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private auth: AuthService,
    private http: HttpClient,
    private notification: NotificationService,
  ) {}

  initUrl: string = 'v1/shell/init'; //this is the one to be used for fetching init vars
  centreCode: string;
  fullTextSearch: boolean;
  viewContents: boolean;
  menu: Menu;

  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      //canActivate: [AuthenticationGuard],
    };
  }

  async Init() {
    const promise = this.http.get<InitResponse>(this.initUrl).toPromise();
    await promise.then(
      (data) => {
        log.debug('data from shell init: ', data);

        if (data) {
          this.centreCode = data.centreCode;
          this.fullTextSearch = data.fullTextSearch;
          this.viewContents = data.viewContents;
          this.menu = data.menu;

          data.menu.items.forEach((item) => {
            if (item.state) {
              this.auth.addAllowedRoutesFromState(item.state);
            } else {
              item.subMenuItems.forEach((subItem) => {
                this.auth.addAllowedRoutesFromState(subItem.state);
              });
            }
          });
        } else {
          this.error('Unable to initialise shell');
        }
      },
      (err) => {
        log.error(err);
      },
    );
  }

  isGlobalCentreCode(): boolean {
    return this.centreCode.toUpperCase() === 'GLOBAL';
  }

  canFullTextSearch(): boolean {
    return this.fullTextSearch;
  }

  canViewContents(): boolean {
    return this.viewContents;
  }

  primaryCentreCode(): string {
    log.debug('centre code is:: ', this.centreCode);
    return this.centreCode;
  }

  notify(message: string) {
    this.notification.notify(message);
  }

  notifyWorking() {
    this.notification.notifyWorking();
  }

  error(err: string) {
    this.notification.error(err);
  }

  hideWorking() {
    this.notification.hideWorking();
  }
}

export interface SubMenuItem {
  label: string;
  state: string;
}

export interface Item {
  label: string;
  subMenuItems: SubMenuItem[];
  state: string;
}

export interface Menu {
  items: Item[];
}

export interface InitResponse {
  buildNumber: string;
  centreCode: string;
  menu: Menu;
  viewContents: boolean;
  fullTextSearch: boolean;
}
